.inline{
  display:inline-block;


}


.gradient {
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0.15) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.65)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.15) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.15) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.15) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0.15) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}


/* .mobile{
width: 100vw;
height: 100vh;
}

.desktop{
width: 100vw;
height: 100vh;
} */

.blink{
  animation: blink-animation 2s steps(5, start) infinite;
  -webkit-animation: blink-animation 2s steps(5, start) infinite;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.pulse1 {

  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite}

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: .9;
    }
  }

  /* *************************************************** */
  /* ANIMAZIONE SCROLL SU ICONA PRIMA IMMAGINE DI PAGINA */
  /* *************************************************** */
  @import url(https://fonts.googleapis.com/css?family=Cabin);

  #blinka {
    margin-top: 90px;
    margin-left:-33px;
    font-family: "Cabin", sans-serif;
    letter-spacing: 12px;
    text-indent: 12px;
    color: #ffffff;
    -webkit-animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
    animation: colorText 5s ease-out infinite, nudgeText 5s ease-out infinite;
  }

  .mouse {
    background: #4e5559 linear-gradient(transparent 0%, transparent 50%, #ffffff 50%, #ffffff 100%);
    position: absolute;
    /* margin-top: 40px; */
    /* top: 90%; */
    z-index: -1;
    bottom: 50px;
    /* right: 48%; */

    width: 52px;
    height: 88px;
    border-radius: 100px;
    background-size: 100% 200%;
    -webkit-animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
    animation: colorSlide 5s linear infinite, nudgeMouse 5s ease-out infinite;
  }
  .mouse:before, .mouse:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
  .mouse:before {
    width: 46px;
    height: 82px;
    background-color: #222a30;
    border-radius: 100px;
  }
  .mouse:after {
    background-color: #ffffff;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    -webkit-animation: trackBallSlide 5s linear infinite;
    animation: trackBallSlide 5s linear infinite;
  }

  @-webkit-keyframes colorSlide {
    0% {
      background-position: 0% 100%;
    }
    20% {
      background-position: 0% 0%;
    }
    21% {
      background-color: #4e5559;
    }
    29.99% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    30% {
      background-color: #4e5559;
      background-position: 0% 100%;
    }
    50% {
      background-position: 0% 0%;
    }
    51% {
      background-color: #4e5559;
    }
    59% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    60% {
      background-color: #4e5559;
      background-position: 0% 100%;
    }
    80% {
      background-position: 0% 0%;
    }
    81% {
      background-color: #4e5559;
    }
    90%, 100% {
      background-color: #ffffff;
    }
  }

  @keyframes colorSlide {
    0% {
      background-position: 0% 100%;
    }
    20% {
      background-position: 0% 0%;
    }
    21% {
      background-color: #4e5559;
    }
    29.99% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    30% {
      background-color: #4e5559;
      background-position: 0% 100%;
    }
    50% {
      background-position: 0% 0%;
    }
    51% {
      background-color: #4e5559;
    }
    59% {
      background-color: #ffffff;
      background-position: 0% 0%;
    }
    60% {
      background-color: #4e5559;
      background-position: 0% 100%;
    }
    80% {
      background-position: 0% 0%;
    }
    81% {
      background-color: #4e5559;
    }
    90%, 100% {
      background-color: #ffffff;
    }
  }
  @-webkit-keyframes trackBallSlide {
    0% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    6% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    14% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    15%, 19% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    28%, 29.99% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    30% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    36% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    44% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    45%, 49% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    58%, 59.99% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    60% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    66% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    74% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    75%, 79% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    88%, 100% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
  }
  @keyframes trackBallSlide {
    0% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    6% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    14% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    15%, 19% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    28%, 29.99% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    30% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    36% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    44% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    45%, 49% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    58%, 59.99% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    60% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
    66% {
      opacity: 1;
      transform: scale(0.9) translateY(5px);
    }
    74% {
      opacity: 0;
      transform: scale(0.4) translateY(40px);
    }
    75%, 79% {
      opacity: 0;
      transform: scale(0.4) translateY(-20px);
    }
    88%, 100% {
      opacity: 1;
      transform: scale(1) translateY(-20px);
    }
  }
  @-webkit-keyframes nudgeMouse {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(8px);
    }
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(8px);
    }
    60% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(8px);
    }
    90% {
      transform: translateY(0);
    }
  }
  @keyframes nudgeMouse {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(8px);
    }
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(8px);
    }
    60% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(8px);
    }
    90% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes nudgeText {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(2px);
    }
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(2px);
    }
    60% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(2px);
    }
    90% {
      transform: translateY(0);
    }
  }
  @keyframes nudgeText {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(2px);
    }
    30% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(2px);
    }
    60% {
      transform: translateY(0);
    }
    80% {
      transform: translateY(2px);
    }
    90% {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes colorText {
    21% {
      color: #4e5559;
    }
    30% {
      color: #ffffff;
    }
    51% {
      color: #4e5559;
    }
    60% {
      color: #ffffff;
    }
    81% {
      color: #4e5559;
    }
    90% {
      color: #ffffff;
    }
  }
  @keyframes colorText {
    21% {
      color: #4e5559;
    }
    30% {
      color: #ffffff;
    }
    51% {
      color: #4e5559;
    }
    60% {
      color: #ffffff;
    }
    81% {
      color: #4e5559;
    }
    90% {
      color: #ffffff;
    }
  }



  /* #nascondi {
    position: fixed;
    transition: top 0.3s;
  } */



  .share  {
    padding:8px;
    /* font-size:40px; */
    position: fixed;
    transition: top 0.3s;
    top: 90%;
    right: 2%;
    background-color: #FFFFFF99;
    border-radius:50px;
  }
